@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-hi5gsx-MuiFormHelperText-root.Mui-error{
  margin-top: 8px;
  margin-left: 0px;
}

/* carousel arrow styling */
.slick-prev:before {
  content: "←";
  color: #d77171;
  font-size: 56px;
}
.slick-next:before {
  content: "→";
  color: #d77171;
  font-size: 56px;
}

/* add same height to the all card in carousel */
.slick-track {
  display: flex;
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
}

.slick-slide.slick-active > div {
  width: 100%;
}

.slick-slide > div {
  width: 100%;
}

/* autocomplete tag color change */
.MuiAutocomplete-tag {
  background: #4c85773b !important;
  color: #4c8577 !important;
}
/* dropdown selected item color change */
.css-1hwtlnr-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #4C85773B !important;
}

@media (max-width: 600px) {
  /* Styles for medium screens */
  .slick-prev:before {
    font-size: 36px;
  }
  .slick-next:before {
    font-size: 36px;
  }
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }
}

.slick-prev {
  width: 56px;
  height: 56px;
  z-index: 1;
}
.slick-next {
  width: 56px;
  height: 56px;
  z-index: 1;
}

/* Firefox */
@supports (scrollbar-width: none) and (-moz-appearance: none){ 
  * {
    scrollbar-width: thin;
    scrollbar-color: #4c8577 #e7e7e7;
  }
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #e7e7e7;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #4c8577;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #4c8577;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #4c8577;
}

/* carousel card left align */
.slick-track {
  display: flex !important;
  gap: 16px;
  margin-left: 0px;
  padding-bottom: 6px;
}

/* align the alert message */
.css-1pxa9xg-MuiAlert-message {
  display: flex;
  align-items: center;
}

@media (max-width: 1600px) and (min-width: 900px) {
  a.fc-col-header-cell-cushion {
    padding: 16px 24px !important;
  }
}

@media (max-width: 900px) and (min-width: 600px) {
  a.fc-col-header-cell-cushion {
    padding: 8px 14px !important;
  }
}